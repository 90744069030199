import(/* webpackMode: "eager" */ "/var/www/truligo.com/proxio-fe/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/var/www/truligo.com/proxio-fe/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/var/www/truligo.com/proxio-fe/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/var/www/truligo.com/proxio-fe/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/truligo.com/proxio-fe/src/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/truligo.com/proxio-fe/src/components/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/truligo.com/proxio-fe/src/components/ServicesList/ServicesList.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/truligo.com/proxio-fe/src/page-components/Home/Search/Search.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/truligo.com/proxio-fe/src/page-components/Home/Search/SearchSection/SearchSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/truligo.com/proxio-fe/src/page-components/Home/ServiceProviders/ServiceProviders.tsx");
